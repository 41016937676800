import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Header";
import { formatLocal } from "../../utils/format";
import Menu from "../Menu";
import { useSelector } from "react-redux";
import { urlApi } from "../../utils/findUrl";

const AllUser = () => {
  const [users, setUsers] = useState(null);
  const lang = useSelector((state) => state.lang);
  useEffect(() => {
    //let url = `${process.env.REACT_APP_URL}/user/all`;
    let url = `${urlApi()}/user/all`;
    axios
      .get(url, { withCredentials: true })
      .then(function (res) {
        console.log(res);
        setUsers(res.data.users);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <>
      <Header />
      <div className="menuMain">
        <Menu />
      </div>
      <div className="allUserGroupe">
        <div className="allUser">
          {users ? (
            users.map((user, index) => {
              return (
                <div key={index} className="cardUser">
                  <div className="cardUser__img">
                    <img src={`img/${user.photo}`} alt="profil" />
                  </div>
                  <div className="cardUser__profil">
                    <p>{user.username}</p>
                    <p>
                      {lang.main.account.member} <br />{" "}
                      {formatLocal(user.compteCreated)}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <p>Loadind ...</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AllUser;
