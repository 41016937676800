import Menu from "./Menu";

const Nav = () => {
  return (
    <nav className="nav">
      <label className="nav__menu" htmlFor="showMenu"></label>
      <input
        type="checkbox"
        className="checkbox__menu"
        id="showMenu"
        name="showMenu"
      />
      <Menu />
    </nav>
  );
};

export default Nav;
